<template>
  <div id="section-content" class="container container-fluid">
    <div class="bar-back">
      <router-link to="/member/lottery/lottoset"><i class="fas fa-chevron-left"></i> หน้าหลัก</router-link>
    </div>
    <div class="p-2 w-100 bg-light main-content align-self-stretch" style="min-height: calc((100vh - 179px) - 50px)">
      <div class="bgwhitealpha text-secondary shadow-sm rounded p-1 px-2 xtarget col-lotto d-flex flex-row justify-content-between mb-1 pb-0">
        <div class="lotto-title">
            <h4><i class="fas fa-ticket-alt"></i> {{ lotteryInformation ? lotteryInformation.group_name : '' }}</h4>
        </div><!-- end title --->
        <a v-b-modal.rule-by-id href="#" class="btn btn-outline-secondary btn-sm mr-1">กติกา
            &amp; วิธีเล่น</a>
      </div>

      <div v-if="isLoading">
        <div class="row px-0 mb-1 mx-0">
          <div class="col-4 p-1 pb-0">
            <router-link
              :to="`/member/lottery/lottoset/${id}`"
              style="align-items: center"
              class="btn-af btn btn-outline-primary btn-block d-flex flex-column active font_size_0_9em pt-2 pb-2">
              <i class="fas fa-award"></i>
              ผล{{ lotteryInformation ? lotteryInformation.group_name : '' }}
            </router-link>
          </div>
          <div class="col-4 p-1 pb-0">
            <router-link
              style="align-items: center"
              :to="`/member/lottery/lottoset/${id}/tang`"
              class="btn-af btn btn-outline-primary btn-block d-flex flex-column font_size_0_9em pt-2 pb-2">
              <i class="fas fa-calculator"></i>
              แทง{{ lotteryInformation ? lotteryInformation.group_name : '' }}
            </router-link>
          </div>
           <div class="col-4 p-1 pb-0">
            <router-link
              style="align-items: center"
              :to="`/member/lottery/lottoset/${id}/poy`"
              class="btn-af btn btn-outline-primary btn-block d-flex flex-column font_size_0_9em pt-2 pb-2">
              <i class="fas fa-calculator"></i>
              รายการที่แทง
            </router-link>
          </div>
        </div>
        <div class="w-100 my-2 border-bottom"></div>
        <div class="bgwhitealpha shadow-sm rounded p-2 mb-1 xtarget col-lotto d-flex flex-column flex-sm-column flex-md-row justify-content-between">
              <h4 class="mb-0 text-center"><i class="fas fa-star"></i> {{ lotteryInformation ? lotteryInformation.group_name : '' }} </h4>
              <div><span class="badge badge-primary font-weight-light w-100">ผลรางวัลออกเลขตามสลากหวย ({{ lotteryInformation ? lotteryInformation.group_name : '' }})</span></div>
          </div>
        <div class="bg-white shadow-sm rounded py-2 px-1 mb-5">
          <div class="row p-0 m-0">
            <div class="col-12 col-sm-12 col-md-12 col-lg-9 px-1">
              <div class="row">
                <div class="col-12">
                  <div
                    class="
                      d-flex
                      flex-row flex-sm-row flex-md-row-reverse
                      justify-content-between justify-content-sm-between justify-content-md-end
                    "
                  >
                    <div class="p-1" >
                      <span class="badge badge-dark font-weight-light">ประจำวันที่</span>
                      {{ lotteryInformation.title }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="w-100 p-1 mt-2">
            <div class="row px-2">
              <div class="col-12 col-sm-12 col-md-6 px-1">
                <div class="card border-dark text-center mb-2">
                  <div class="card-header p-1">
                    <b>สี่ตัวตรง</b><br />
                    <small>(มูลค่า {{ lotteryInformation ? numberWithCommas(lotteryInformation.my_price[8].price) : '' }} บาท)</small>
                  </div>
                  <div class="card-body p-1">
                    <h3 class="card-text">{{ getResultLotto.reward6 }}</h3>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 px-1">
                <div class="card border-dark text-center mb-2">
                  <div class="card-header p-1">
                    <b>สามตัวตรง</b><br />
                    <small>(มูลค่า {{ lotteryInformation ? numberWithCommas(lotteryInformation.my_price[0].price) : '' }} บาท)</small>
                  </div>
                  <div class="card-body p-1">
                    <h3 class="card-text">{{ getResultLotto.reward6.substring(1, 4) }}</h3>
                  </div>
                </div>
              </div>
            </div>
            <div class="row px-2">
              <div class="col-6 col-sm-6 col-md-3 p-1">
                <div class="card border-dark text-center">
                  <div class="card-header p-1">
                    <b>สี่ตัวโต๊ด</b><br />
                    <small style="line-height: 1.2; display: inline-block">({{ lotteryInformation ? numberWithCommas(lotteryInformation.my_price[9].price) : '' }} ฿)</small>
                  </div>
                  <div class="card-body p-1">
                    <h5 class="card-text">{{ getResultLotto.reward6 }}</h5>
                  </div>
                </div>
              </div>
              <div class="col-6 col-sm-6 col-md-3 p-1">
                <div class="card border-dark text-center">
                  <div class="card-header p-1">
                    <b>สามตัวโต๊ด</b><br />
                    <small style="line-height: 1.2; display: inline-block">({{ lotteryInformation ? numberWithCommas(lotteryInformation.my_price[1].price) : '' }} ฿)</small>
                  </div>
                  <div class="card-body p-1">
                    <h5 class="card-text">{{ getResultLotto.reward6.substring(1, 4) }}</h5>
                  </div>
                </div>
              </div>
              <div class="col-6 col-sm-6 col-md-3 p-1">
                <div class="card border-dark text-center">
                  <div class="card-header p-1">
                    <b>สองตัวหน้า</b><br />
                    <small style="line-height: 1.2; display: inline-block">({{ lotteryInformation ? numberWithCommas(lotteryInformation.my_price[10].price) : '' }} ฿)</small>
                  </div>
                  <div class="card-body p-1">
                    <h5 class="card-text">{{  getResultLotto.reward6.substring(0, 2) }}</h5>
                  </div>
                </div>
              </div>
              <div class="col-6 col-sm-6 col-md-3 p-1">
                <div class="card border-dark text-center">
                  <div class="card-header p-1">
                    <b>สองตัวหลัง</b><br />
                    <small style="line-height: 1.2; display: inline-block">({{ lotteryInformation ? numberWithCommas(lotteryInformation.my_price[4].price) : '' }} ฿)</small>
                  </div>
                  <div class="card-body p-1">
                    <h5 class="card-text">{{ getResultLotto.reward6.substring(2, 4) }}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-rule-by-id-modal :advice="this.advice" />
  </div>
</template>

<script>
import ruleByIdModal from '@/components/modals/RuleByIdModalHtml'
import { mapActions, mapGetters } from 'vuex'
export default {
  components: {
    'app-rule-by-id-modal': ruleByIdModal
  },
  async created () {
    this.id = parseInt(this.$route.params.id)
    try {
      this.setLoading(true)
      await this.getInquiryRoundInfomationByID(this.id)
      this.lotteryInformation = this.getInquiryRoundInfomation
      this.advice = this.lotteryInformation.advice
      await this.fetchResultLotto(this.$route.params.id)
      this.setLoading(false)
    } catch (error) {
      this.setLoading(false)
    }
    this.isLoading = true
  },
  methods: {
    ...mapActions(['setLoading', 'fetchResultLotto', 'getInquiryRoundInfomationByID']),
    numberWithCommas (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  },
  computed: {
    ...mapGetters(['getInquiryRoundInfomation', 'getResultLotto'])
  },
  data () {
    return {
      id: null,
      lotto: {},
      isLoading: false,
      advice: '',
      lotteryInformation: null
    }
  }
}
</script>

<style>
</style>
